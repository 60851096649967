import {Card, ConfigProvider, Layout, Typography} from 'antd';
import EnrollmentForm                                          from './EnrollmentForm';
import React                                          from 'react';
import {WorkflowConfig}            from './api/enrollement';
import {selectToken} from './store/authSlice';
import {useSelector}                          from 'react-redux';
import {Content, Footer, Header}                               from 'antd/lib/layout/layout';
import {PoweredBy} from './icons';

const headerStyle: React.CSSProperties = {
  textAlign: 'center',
  color: '#fff',
  height: 36,
  lineHeight: '64px',
  backgroundColor: '#e46e28'
}

function startOver() {
  sessionStorage.removeItem('enrollmentId')
  window.location.reload()
}

const EnrollmentUI = (props: {config: WorkflowConfig}) => {
  const config = props.config
  const token = useSelector(selectToken)

  return (
    <>
      {
        token &&
        (
          <ConfigProvider theme={{
            token: {
              colorText: config.color.fontColor,
              colorTextLabel: config.color.fontColor
            }
          }}>
            <Layout style={{
                minHeight: '100vh'
              }}>
              <Header style={{...headerStyle, display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography.Link onClick={() => startOver()}
                                 style={{color: config.color.startOverFontColor}}>
                  Start Over</Typography.Link>
                <Typography.Text>Powered by Ruckus</Typography.Text>
              </Header>
              <Content>
                <Card style={{ height: '100%', padding: 8 }}>
                  <EnrollmentForm
                    uiColorConfig={{  ...config.color }}
                    uiStyleConfig={{  ...config.styles }}
                    firstActionStepId={config.firstActionStepId}
                  />
                </Card>
              </Content>
              <Footer style={{ display: 'flex', justifyContent: 'end' }}>
                {!config.styles.disablePoweredBy && <PoweredBy/>}
              </Footer>
            </Layout>
          </ConfigProvider>
        )
      }
    </>
  )
}

export default EnrollmentUI
