import { WorkflowConfig } from '../api/enrollement';

export interface UIColorSchema {
  fontHeaderColor: string
  fontColor: string
  backgroundColor: string
  buttonColor: string
  buttonFontColor: string
  startOverFontColor: string
}

export interface UIStyleSchema {
  logoSize: string
  headerFontSize: number
  logoImage?: string
  backgroundImage?: string
  iconImage?: string
  disablePoweredBy: boolean
  wifi4EUNetworkId?: string
  welcomeTitle: string
  welcomeSubTitle: string
}



export const DefaultConfiguration : WorkflowConfig = {
  color: {
    fontHeaderColor: 'var(--acx-neutrals-100)',
    backgroundColor: 'var(--acx-primary-white)',
    fontColor: 'var(--acx-neutrals-100)',

    buttonFontColor: 'var(--acx-primary-white)',
    buttonColor: 'var(--acx-accents-orange-50)',
    startOverFontColor: 'var(--acx-primary-white)'
  },
  styles: {
    disablePoweredBy: false,
    logoSize: 'MEDIUM',
    headerFontSize: 16,
    welcomeTitle: '',
    welcomeSubTitle: ''
  },
  firstActionStepId: ''
}
