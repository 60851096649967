import { Button, Form, FormInstance, Image, Input, Space, Spin, Typography } from 'antd'
import AupAction from './actions/AupAction'
import React, { useCallback, useEffect, useState } from 'react'
import DataPromptAction from './actions/DataPromptAction'

import {
  ActionInput,
  EnrollmentStepConfig, useLazyGetCurrentStepQuery, useLazyGetWorkflowStepQuery,
  useUpdateWorkflowStepMutation
} from './api/enrollement';
import { BaseAction } from './types/BaseAction';
import { AupConfig } from './types/AupActionConfig';
import DisplayMessageAction from './actions/DisplayMessageAction';
import { DisplayMessageActionConfig } from './types/DisplayMessageActionConfig';
import { UIColorSchema, UIStyleSchema } from './types/UIConfiguration';
import { useForm } from 'antd/lib/form/Form';
import FormItem from 'antd/lib/form/FormItem';
import { UserInfoConfig } from './types/UserInfoConfig';
import { DpskAction } from './actions/DpskAction';
import { MacRegActionConfig } from './types/MacRegActionConfig';
import MacRegAction from './actions/MacRegAction';
import { MacRegOnboarded } from './actions/MacRegOnboarded';
import { Wifi4euBanner } from './icons';

function Action(actionType: string, stepType: string, config: BaseAction, variables: any, form: FormInstance, setDisableContinue: React.Dispatch<React.SetStateAction<boolean>>) {

  if (stepType === 'end') {
    switch (actionType) {
      case 'DPSK':
        return <DpskAction data={variables} />
      case 'MAC_REG':
        return <MacRegOnboarded data={variables} />
      default:
        return null;
    }
  } else {
    switch (actionType) {
      case 'AUP':
        return <AupAction config={config as AupConfig} form={form} setDisableContinue={setDisableContinue} />
      case 'DISPLAY_MESSAGE':
        return <DisplayMessageAction config={config as DisplayMessageActionConfig} form={form} setDisableContinue={setDisableContinue} />
      case 'DATA_PROMPT':
        return <DataPromptAction config={config as UserInfoConfig} form={form} setDisableContinue={setDisableContinue} />
      case 'MAC_REG':
        return <MacRegAction config={config as MacRegActionConfig} form={form} setDisableContinue={setDisableContinue} />
      default:
        return null
    }
  }

}

const EnrollmentForm = ({ uiColorConfig, uiStyleConfig, firstActionStepId }: {
  uiColorConfig: UIColorSchema,
  uiStyleConfig: UIStyleSchema,
  firstActionStepId: string
}) => {
  const [stepId, setStepId] = useState<string>('')
  const [actionType, setActionType] = useState<string>('')
  const [disableContinue, setDisableContinue] = React.useState(false)
  const [getStep, { isLoading: isLoadingStep, isSuccess, data: stepConfig }] = useLazyGetWorkflowStepQuery()
  const [actionConfig, setActionConfig] = useState<BaseAction>()
  const [backButtonText, setBackButtonText] = useState('Back')
  const [continueButtonText, setContinueButtonText] = useState('Start')
  const [updateStep, { isLoading: isUpdatingStep }] = useUpdateWorkflowStepMutation()
  const [form] = useForm<ActionInput>()
  const [errorMessage, setErrorMessage] = useState<string>('')
  const [direction, setDirection] = useState<'next' | 'prev'>('next')
  const [ getCurrentStep, { data: currentStepConfig } ] = useLazyGetCurrentStepQuery()
  const [variables, setVariables] = useState()
  const [stepType, setStepType] = useState<string>('start')

  const onStepConfigChanged = useCallback((stepConfig: EnrollmentStepConfig) => {
    if (stepConfig.config.stepType === 'start') {
      updateStep({ stepId: stepConfig.config.stepId, body: null }).unwrap().then(() => {
        getCurrentStep(null)
      }).catch(error => setErrorMessage(error.message))
    } else {
      setStepType(stepConfig.config.stepType)
      setStepId(stepConfig.config.stepId)
      const config = JSON.parse(stepConfig.config.actionConfiguration!) as BaseAction
      setActionConfig(config)
      setActionType(stepConfig.config.actionType!)
      if (stepConfig.step) {
        if (stepConfig.step.input) {
          form.setFieldsValue(stepConfig.step.input)
        }
        if (stepConfig.step.variables) {
          setVariables(stepConfig.step.variables)
        }
      }
      form.setFieldValue('actionType', stepConfig.config.actionType!)
      if (config.backButtonText) {
        setBackButtonText(config.backButtonText)
      }
      if (config.continueButtonText) {
        setContinueButtonText(config.continueButtonText)
      }
    }
  }, [form])

  useEffect(() => {
    getCurrentStep(null)
  }, [])

  useEffect(() => {
    if (!currentStepConfig) {
      return
    }
    onStepConfigChanged(currentStepConfig)
  }, [currentStepConfig])

  useEffect(() => {
    if (!stepConfig || !isSuccess) {
      return
    }
    onStepConfigChanged(stepConfig)
  }, [stepConfig])

  const onFinish = async (values: ActionInput) => {
    try {
      await updateStep({ stepId, body: values }).unwrap();
      form.resetFields();
      getStep({ stepId, nav: direction })
    } catch (error) {
      console.error(error)
      setErrorMessage('Unable to finish operation')
    }
  }

  const handleOnBack = () => {
    setDisableContinue(false)
    setDirection('prev')
    try {
      getStep({ stepId, nav: 'prev' })
    } catch (error) {
      console.error(error)
      setErrorMessage('Unable to get previous step')
    }
  }

  const handleContinue = (cont: Boolean) => {
    setDirection(cont ? 'next' : 'prev')
    form.submit()
  }

  const getImageSize = (s: string) => {
    if (s === 'SMALL') return 105
    else if (s == 'LARGE') return 105 * 2.25
    return 105 * 1.5
  }

  return (
    <>
      <Form
        form={form}
        name="userForm"
        onFinish={onFinish}
        data-testid='enrollment-form'
        layout='vertical'>

        <FormItem hidden name={'actionType'}>
          <Input value={actionType} />
        </FormItem>

        <Space direction='vertical' align='center' style={{ width: '100%' }}>
          {uiStyleConfig.wifi4EUNetworkId && <Wifi4euBanner/>}
          {
            uiStyleConfig.logoImage &&
            <Image placeholder width={getImageSize(uiStyleConfig.logoSize)} height={getImageSize(uiStyleConfig.logoSize)} src={uiStyleConfig.logoImage}/>
          }
          {actionConfig?.title && <Typography.Title style={{fontSize: uiStyleConfig.headerFontSize}}>{actionConfig.title}</Typography.Title>}
          { Action(actionType, stepType, actionConfig!, variables, form, setDisableContinue) }
          <Space>
            {(stepType === 'basic') &&
              <>
                {(firstActionStepId !== stepId)  && <Button style={{backgroundColor: uiColorConfig.buttonColor, color: uiColorConfig.buttonFontColor}} onClick={handleOnBack}>{ backButtonText }</Button> }
                <Button type="primary" style={{backgroundColor: uiColorConfig.buttonColor, color: uiColorConfig.buttonFontColor}} onClick={() => handleContinue(true)} disabled={disableContinue}>{continueButtonText}</Button>
              </>
            }
          </Space>
          <Typography.Text type="danger">{errorMessage}</Typography.Text>
        </Space>
      </Form>
      <Spin spinning={isLoadingStep || isUpdatingStep} fullscreen data-testid='spinner' />
    </>
  )
}

export default EnrollmentForm
